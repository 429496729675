.close-detail-button {
    margin: 3% auto;
    align-items: center;
    background-image: linear-gradient(144deg, #AF40FF, #5B42F3 50%, #00DDEB);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    font-family: Phantomsans, sans-serif;
    font-size: 18px;
    justify-content: center;
    line-height: 1em;
    max-width: 100%;
    min-width: 140px;
    padding: 3px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
    transition: all .3s;
}

.close-detail-button:active,
.close-detail-button:hover {
    outline: 0;
}

.close-detail-button span {
    background-color: rgb(5, 6, 45);
    padding: 16px 24px;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    transition: 300ms;
}

.close-detail-button:hover span {
    background: none;
}

.close-detail-button:active {
    transform: scale(0.9);
}


.modal-content {
    border-radius: 23px;
    padding: 5px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    background-image: linear-gradient(144deg, #AF40FF, #5B42F3 50%, #00DDEB);
}

.modal-body {
    background: rgb(5, 6, 45);
}

.modal-header {
    border-radius: 20px 20px 0 0;
    background: rgb(5, 6, 45);
    border: 0;
}

.modal-footer {
    border-radius: 0 0 20px 20px;
    background: rgb(5, 6, 45);
    border: 0;
}